import React from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ResetEmailButton from 'rev.sdk.js/Components/ResetEmailButton';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as Theme from '../../Theme';
import qs from 'query-string';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {formSpec, lawServiceSpec} from './form-spec';
import RjsfAddressField from '../../Components/RjsfAddressField';
import TwpaaUserProfileRevisionButton from '../../Components/TwpaaUserProfileRevisionButton';
import TwpaaUserProfilePunishmentRecordsButton from '../../Components/TwpaaUserProfilePunishmentRecordsButton';
import RegisterProgress from '../../Components/RegisterProgress';
import * as ValidationUtil from '../../Utils/ValidationUtil';
import _ from 'lodash';

function ProfilePage(props) {
  const {id} = qs.parse(props.location.search);
  const [article, setArticle] = React.useState(null);
  const [user, setUser] = useOutlet('user');
  const [finalFormSpec, setFinalFormSpec] = React.useState(null);

  React.useEffect(() => {
    const nextFormSpec = _.cloneDeep(formSpec);
    // nextFormSpec.schema.properties.address = genAddressField();
    nextFormSpec.schema.properties = {
      ...nextFormSpec.schema.properties,
      ...lawServiceSpec,
    };
    setFinalFormSpec(nextFormSpec);
  }, [user]);

  React.useEffect(() => {
    async function fetchData() {
      if (!id) {
        setArticle(null);
      } else {
        AppActions.setLoading(true);
        try {
          setArticle(
            await JStorage.fetchOneDocument('Article_Default', {id: id}),
          );
        } catch (ex) {
          console.warn('REV_DBG', ex);
        }
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  if (!user) {
    return null;
  } else if (id) {
    return (
      <Wrapper>
        {article && (
          <>
            <h1>{article.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: article.raw_html?.enabled
                  ? article.raw_html.html
                  : article.html,
              }}
            />
          </>
        )}
      </Wrapper>
    );
  }

  const rjsfProps = {
    fields: {
      'rev-address-field': RjsfAddressField,
    },
  };

  return (
    <Wrapper id="twpaa-user-profile">
      {user.data?.status === 'reviewing' && <RegisterProgress current={3} />}

      <h2 style={{marginBottom: 0}}>個人資訊</h2>

      {finalFormSpec && (
        <GenericForm
          {...finalFormSpec}
          rjsfProps={rjsfProps}
          instance={user.data}
          renderCustomSubmitButton={({values: formData}) => {
            return (
              <Ant.Button
                type="primary"
                style={{marginTop: 20}}
                onClick={async () => {
                  for (const k in formData) {
                    if (
                      typeof user.data[k] === 'string' &&
                      formData[k] === undefined
                    ) {
                      formData[k] = '';
                    }
                  }

                  const errFields = ValidationUtil.validateProfileUpdateForm(
                    formData,
                  );

                  if (Object.keys(errFields).length > 0) {
                    alert(
                      Object.keys(errFields)
                        .map((k) => errFields[k])
                        .join('\n'),
                    );
                    return;
                  }

                  AppActions.setLoading(true);
                  try {
                    const resp = await AppActions.twpaaUpdateUserProfile(
                      formData,
                    );
                    setUser({
                      ...user,
                      data: {
                        ...user.data,
                        ...resp,
                      },
                    });
                    Ant.message.success(
                      '已成功更新，您可於本頁最下方 [其他功能][修改紀錄] 查看所有修改的歷程',
                    );
                  } catch (ex) {
                    Ant.message.error(`API error ${ex}`);
                  }
                  AppActions.setLoading(false);
                }}>
                更新個人資料
              </Ant.Button>
            );
          }}
        />
      )}

      <div style={{color: '#aaa', margin: '15px 0'}}>
        <div>備註： </div>
        <div>
          依據專利師法第 8
          條之規定：專利師於第一項第一款及第三款規定記載事項有變更時，應自事實發生之日起三十日內，向專利專責機關申報備查。
          本會於辦理會員資料異動時，將協助轉知經濟部智慧財產局承辦人，如會員變更之資料屬第一項第一款（姓名、性別、出生年月日、住址、身分證明文件字號）及第三款（執行業務處所及地址）時，請自事實發生之日起三十日內，由專利師向經濟部智慧財產局申報備查。
        </div>
      </div>

      <h2>其他功能</h2>
      <div style={{display: 'flex', gap: 12}}>
        <TwpaaUserProfileRevisionButton owner={user?.sub} />
        <TwpaaUserProfilePunishmentRecordsButton profile={user?.data} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  h2 {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid ${Theme.colors.main};
  }

  &#twpaa-user-profile {
    .form-group.field.field-string,
    .form-group.field.field-boolean {
      margin-left: 20px;
      padding-top: 30px;
      padding-bottom: 6px;
      border-bottom: 1px solid #ccc;
    }

    .ant-row.ant-form-item-row {
      gap: 8px;

      .ant-col.ant-col-24.ant-form-item-label {
        flex: 0 0 100px;
      }
      .ant-col.ant-col-24.ant-form-item-control {
        flex: 1 1 calc(100% - 110px);
      }
    }

    .ant-form-item-control-input-content fieldset {
      margin-top: 30px;
    }
  }
`;

export default withPageEntry(ProfilePage);
