export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: [
      'phone',
      'email',
      'contact_address',
      'expertise',
      'receipt_name',
      'chpatent',
    ],
    properties: {
      photo: {
        title: '照片',
        type: 'string',
        readOnly: true,
      },
      name: {
        title: '中文姓名',
        type: 'string',
        readOnly: true,
      },
      name_eng: {
        title: '英文姓名 (同護照/英文半形)',
        type: 'string',
        readOnly: true,
      },
      gender: {
        type: 'boolean',
        title: '性別',
        enum: [true, false],
        enumNames: ['男', '女'],
        readOnly: true,
      },
      birthday: {
        title: '出生年月日',
        type: 'string',
        format: 'date',
        readOnly: true,
      },
      phone: {
        title: '手機',
        type: 'string',
      },
      email: {
        title: 'Email(1)',
        type: 'string',
      },
      email_2: {
        title: 'Email(2)',
        type: 'string',
      },
      contact_address: {
        title: '聯絡地址',
        type: 'object',
        properties: {
          city: {
            type: 'string',
          },
          district: {
            type: 'string',
          },
          zip: {
            type: 'string',
          },
          detail: {
            type: 'string',
          },
        },
      },
      national_examination_year: {
        title: '國考年度 (西元)',
        type: 'string',
        readOnly: true,
      },
      certificate_number: {
        title: '專利師證書字號',
        type: 'string',
        readOnly: true,
      },
      trademark_attorney: {
        title: '商標代理人登錄字號',
        type: 'string',
      },
      chpatent: {
        type: 'boolean',
        title: '中國專利代理師證書',
        enum: [true, false],
        enumNames: ['取得', '未取得'],
      },
      chpatent_detail: {
        title: '取得中國專利代理師證書年份',
        type: 'string',
      },
      expertise: {
        title: '專長',
        type: 'object',
        properties: {
          mechanical: {
            type: 'boolean',
            title: '機械',
          },
          operational: {
            type: 'boolean',
            title: '作業運輸',
          },
          biotechnology: {
            type: 'boolean',
            title: '生技醫藥',
          },
          chemical: {
            type: 'boolean',
            title: '化工',
          },
          electronic: {
            type: 'boolean',
            title: '電子及電力',
          },
          semiconductor: {
            type: 'boolean',
            title: '半導體製程及元件',
          },
          information: {
            type: 'boolean',
            title: '資通訊',
          },
          photoelectric: {
            type: 'boolean',
            title: '光電物理',
          },
          industrial_design: {
            type: 'boolean',
            title: '工業設計',
          },
          supplies: {
            type: 'boolean',
            title: '民生用品',
          },
          etc: {
            type: 'boolean',
            title: '其他',
          },
          etc_text: {
            type: 'string',
            title: '其他',
          },
        },
      },
      'rev-line-1': {
        title: '執行業務處所',
        type: 'string',
      },
      agency_name: {
        title: '中文全稱',
        type: 'string',
      },
      agency_name_eng: {
        title: '英文全稱',
        type: 'string',
      },
      agency_address: {
        title: '執行業務處所地址',
        type: 'object',
        properties: {
          city: {
            type: 'string',
          },
          district: {
            type: 'string',
          },
          zip: {
            type: 'string',
          },
          detail: {
            type: 'string',
          },
        },
      },
      agency_telephone_prefix: {
        title: '聯絡電話(國碼)',
        type: 'string',
      },
      agency_telephone: {
        title: '聯絡電話',
        type: 'string',
      },
      agency_telephone_ext: {
        title: '聯絡電話(分機)',
        type: 'string',
      },
      agency_fax: {
        title: '傳真',
        type: 'string',
      },
      agency_business_id_number: {
        title: '統一編號',
        type: 'string',
      },
      agency_change_notify: {
        type: 'boolean',
        title:
          '同步變更訴代名冊「執行業務處所」登錄資料（目前訴代名冊「執行業務處所」登錄資料請見下方【訴代名冊專區】） ，勾選即通知秘書處訴代名冊業務承辦人員，不需同步變更請勿勾選。',
        default: false,
      },
      'rev-line-2': {
        title: '相關資訊',
        type: 'string',
      },
      public: {
        type: 'boolean',
        title: '刊登資料於公會網頁',
        enum: [true, false],
        enumNames: ['同意', '不同意'],
      },
      receipt_name: {
        title: '收據抬頭',
        type: 'string',
      },
      business_id_number: {
        title: '統編',
        type: 'string',
      },
      join_date: {
        title: '入會日期',
        type: 'string',
        format: 'date',
        readOnly: true,
      },
      withdraw_date: {
        title: '退會日期',
        type: 'string',
        format: 'date',
        readOnly: true,
      },
      'rev-line-3': {
        title: '秘書聯絡資訊',
        type: 'string',
      },
      secretary_name: {
        title: '秘書姓名',
        type: 'string',
      },
      secretary_phone: {
        title: '秘書電話',
        type: 'string',
      },
      secretary_email: {
        title: '秘書 Email',
        type: 'string',
      },
    },
  },
  uiSchema: {
    chpatent: {
      'ui:widget': 'radio',
    },
    chpatent_detail: {
      'ui:help': '請填寫西元年份，如:2020。如未取得則無需填寫。',
    },
    photo: {
      'ui:widget': 'rev-single-image-uploader',
    },
    description: {
      'ui:widget': 'textarea',
    },
    'rev-line-1': {
      'ui:widget': 'rev-line',
      'ui:options': {label: false},
    },
    'rev-line-2': {
      'ui:widget': 'rev-line',
      'ui:options': {label: false},
    },
    public: {
      'ui:widget': 'radio',
    },
    gender: {
      'ui:widget': 'radio',
    },
    contact_address: {
      'ui:field': 'rev-address-field',
      'rev:css':
        "flex-direction: row; margin-left: 20px; border-bottom: 1px solid #ccc; & > label { &::before {content: '*'; color: red; padding-right: 4px; } } ",
    },
    agency_address: {
      'ui:field': 'rev-address-field',
      'rev:css':
        'flex-direction: row; margin-left: 20px; border-bottom: 1px solid #ccc;',
    },
    'rev-line-3': {
      'ui:widget': 'rev-line',
      'ui:options': {label: false},
    },
    'rev-line-4': {
      'ui:widget': 'rev-line',
      'ui:options': {label: false},
    },
    'rev-line-5': {
      'ui:widget': 'rev-line',
      'ui:options': {label: false},
    },
    first_login: {
      checked: {
        'ui:widget': 'radio',
        'ui:options': {label: false},
      },
      first_login_date: {
        'ui:help': '格式為yyyy-mm-dd, 例如: 2024-01-23',
      },
      login_qualification: {
        'ui:help':
          '依《專利師公會會員辦理專利民事訴訟代理業務作業要點》第五條之上述款項申請首次登錄。',
      },
      stay_login: {
        checked: {
          'ui:widget': 'radio',
          'ui:options': {label: false},
        },
        cancel_login_date: {
          'ui:help': '格式為yyyy-mm-dd, 例如: 2024-01-23',
        },
      },
    },
  },
};

export const lawServiceSpec = {
  'rev-line-5': {
    title: '訴代專區',
    type: 'string',
  },
  first_login: {
    type: 'object',
    title: '完成訴代名冊首次登錄',
    properties: {
      checked: {
        type: 'boolean',
        title: '確認',
        enum: [true, false],
        enumNames: ['是', '否'],
        readOnly: true,
      },
    },
    dependencies: {
      checked: {
        oneOf: [
          {
            properties: {
              checked: {
                enum: [true],
              },
              login_number: {
                title: '登錄序號',
                type: 'string',
                readOnly: true,
              },
              agency: {
                title: '執行業務處所',
                type: 'string',
                readOnly: true,
              },
              login_qualification: {
                title: '登錄資格條件',
                type: 'string',
                readOnly: true,
              },
              first_login_date: {
                title: '首次登錄時間',
                type: 'string',
                readOnly: true,
              },
              stay_login: {
                title: '維持訴代名冊登錄資格',
                type: 'object',
                properties: {
                  checked: {
                    type: 'boolean',
                    title: '確認',
                    enum: [true, false],
                    enumNames: ['是', '否'],
                    readOnly: true,
                  },
                },
                dependencies: {
                  checked: {
                    oneOf: [
                      {
                        properties: {
                          checked: {
                            enum: [true],
                          },
                        },
                      },
                      {
                        properties: {
                          checked: {
                            enum: [false],
                          },
                          cancel_login_date: {
                            title: '取消登錄時間',
                            type: 'string',
                            readOnly: true,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            properties: {
              checked: {
                enum: [false],
              },
            },
          },
        ],
      },
    },
  },
};
