import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';

export default function TwpaaUserProfilePunishmentRecordsButton(props) {
  const {size = 'medium', profile} = props;
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        size={size}
        onClick={() => {
          setModalData({visible: true, profile});
        }}>
        懲處紀錄
      </Ant.Button>
      <TwpaaModal
        close={() => setModalData({visible: false})}
        {...modalData}
        {...props}
      />
    </>
  );
}

function TwpaaModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={960}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {profile} = props;

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>懲處紀錄</h2>

      <Ant.Table
        columns={[
          {
            title: '懲處機關',
            key: 'punishment_agency',
            dataIndex: 'punishment_agency',
          },
          {
            title: '案號',
            key: 'case_number',
            dataIndex: 'case_number',
          },
          {
            title: '決議主文',
            key: 'resolution_text',
            dataIndex: 'resolution_text',
          },
          {
            title: '決議日期',
            key: 'resolution_date',
            dataIndex: 'resolution_date',
          },
        ]}
        rowKey={'id'}
        dataSource={profile?.punishment_records || []}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
